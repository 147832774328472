footer {
  background-color: #003443;

  .footer-info {
    display: flex;
    justify-content: space-between;

    margin: 0 auto;

    @include breakpoint(medium) {
      display: block;
    }

    .footer-left {
      width: calc(100% - 295px);

      background: url(/wp-content/themes/tourlabs-mspqi/assets/images/footer-bg.png) no-repeat;
      background-size: 298px 337px;
      background-position: left center;

      @include breakpoint(medium) {
        width: 100%;
      }

      .footer-search-wrap {
        display: flex;
        justify-content: flex-end;
        padding-top: 55px;
        padding-right: 20%;

        @include custom_breakpoint(1180) {
          padding: 55px 50px 0;
        }

        @include breakpoint(medium) {
          justify-content: center;
        }

        .footer-search {
          border: 1px solid #05b3b9;
          max-width: 667px;
          width: 100%;
          border-radius: 40px;
          display: flex;
          align-items: center;


          input {
            background-color: transparent;
            border: none;
            box-shadow: none;
            margin: 0 0;
            padding: 0 26px;
            color: #fff;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              border: none;
              -webkit-text-fill-color: #fff;
              -webkit-box-shadow: none;
              transition: background-color 5000s ease-in-out 0s;
            }

            &::-webkit-search-cancel-button {

              -webkit-appearance: none;
            }
          }

          button {
            border-left: 1px solid #05b3b9;
            padding-left: 20px;
            padding-right: 20px;
            height: 100%;
          }
        }
      }

      .footer-details {
        display: flex;
        justify-content: space-between;
        padding: 90px 120px;

        @include custom_breakpoint(1280) {
          padding: 90px 90px;

          .cell {
            width: 40%;
            padding-bottom: 30px;
          }
        }

        @include custom_breakpoint(1180) {
          padding: 90px 50px;
        }

        @include breakpoint(medium) {
          padding: 90px 10%;
        }

        @include breakpoint(small) {
          padding: 50px 10% 0;

          .cell {
            width: 100%;

          }
        }


        article {
          @include breakpoint(medium) {
            padding-bottom: 30px;
          }

          &.footer-logo {

              h1{
                margin-left: -15px;
                a{
                  color:#fff;
                  display:flex;
                  align-items: center;
                  color:#fff;
                  font-family: TT-Norms, sans-serif;
                p{
                  margin-bottom:0;
                  &:first-child{
                   
                    strong{
                      font-size:15px;
                      line-height:25px;
                      display:block;
                      transform:rotate(-90deg);
                      letter-spacing: 0.03em;
                      color:#fff;
                      &:after{
                        content:"";
                        display:block;
                        height:1px;
                        background-color:#fff;
                        width:95%;
                        position:absolute;
                      }
                    }
                  }

                  &:last-child{
                    font-size:17px;
                    line-height:20px;
                    letter-spacing: 0.025em;
                    span{
                      color:#fff;
                      display:block;
                      max-width:200px;
                      strong{
                        color:#fff;
                      }
                    }
                  }
                 
                }
              }
            }
         
            img {
              max-width: 197px;
              image-rendering: pixelated;
            }

            p {
              max-width: 230px;
            }
          }

          &.footer-addr {



            .addr {
              max-width: 200px;
            }

            .footer-tel {
              display: flex;
              align-items: center;
              padding-top: 27px;
              margin-left: 4px;

              &::before {
                content: "";
                width: 12px;
                height: 22px;
                background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-phone.png) no-repeat;
                background-size: contain;
                background-position: center center;
                display: inline-block;
                margin-right: 14px;


              }
            }

            .footer-email {
              display: flex;
              align-items: center;
              padding-top: 5px;

              &::before {
                content: "";
                width: 19px;
                height: 15px;
                background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-email.png) no-repeat;
                background-size: contain;
                background-position: center center;
                display: inline-block;
                margin-right: 14px;

              }
            }

          }

          ul {
            li {
              a {
                color: #05b2b8;

                @include breakpoint(medium) {
                  font-size: 14px;
                }
              }
            }
          }

          p {
            color: #05b2b8;
            margin: 0 0;
            font-size: 16px;
            line-height: 24px;

            @include breakpoint(medium) {
              font-size: 14px;
              line-height: 18px;
            }

            &.font-xs {
              font-size: 12px;
              line-height: 16px;
            }

            &.white {
              color: #fff;

              a {
                color: #fff;
              }
            }
          }
        }
      }

      .footer-copyright {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding: 32px 120px;

        @include breakpoint(medium) {
          padding: 32px 2%;
          text-align: center;
        }

        p {
          color: #05b3b9;
          font-size: 10px;
          line-height: 20px;
          font-weight: 400;
          margin: 0 0;
          letter-spacing: 0.1em;

          a {
            display: inline-block;
            padding-left: 5px;
            text-align: left;



            @include breakpoint(medium) {
              padding-top: 10px;
            }

          }
        }

      }
    }

    .footer-right {
      padding-top: 70px;
      min-width: 295px;
      background-color: #fff;
      text-align: center;

      @include breakpoint(medium) {
        display: none;
      }
    }

  }

  .hide-for-large {
    display: flex;
    background: #fff;
    padding: 40px 0;
    justify-content: space-around;
    align-items: center;
  }


}
