.promo {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.overlay {

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &.promo-home {
    height: 800px;

    &.overlay {

      &::before {

        background-color: rgba(0, 0, 0, 0.7);

      }
    }

  }

  &.promo-subpage {
    height: 666px;
  }

  &::after {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    background-color: rgba(255, 255, 255, 0.23);
    position: absolute;
    bottom: 10px;
    left: 0;

  }



  .promo-wrapper {
    color: #fff;

    h4 {
      @include breakpoint(small) {
        font-size: 16px;
      }
    }

    h2 {
      letter-spacing: 0.02em;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 38px;

      @include breakpoint(small) {
        font-size: 40px;
        line-height: 45px;
      }
    }

  }
}


.promo-blog {
  min-height: 482px;
  background: url(/wp-content/themes/tourlabs-mspqi/assets/images/SUB/01_Blog_Subpage_promo.jpg) no-repeat center;
  background-size: Cover;
}

.promo-404 {
  height: 100vh;
  background: url(/wp-content/themes/tourlabs-mspqi/assets/images/SUB/404_error_promo.jpg) no-repeat center;
  background-size: cover;
}
