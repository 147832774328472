.anim-zoom img {
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}
.anim-zoom:hover img {
  transform: scale(1);
}

.anim-zoom-light img {
  transform: scale(0.98);
  transition: all 0.2s ease-in-out;
}
.anim-zoom-light:hover img {
  transform: scale(1);
}

.anim-zoom-overflow {
  overflow: hidden;
  &:hover img {
    transform: scale(1.05);
  }

  img {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
}


@keyframes continuous-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-trigger {
  &:hover {
    .rotate-target, img {
      animation: continuous-rotation 3s infinite linear;
    }
  }
}

@keyframes shake {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {
    transform: rotate(15deg);
  }
  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    transform: rotate(-15deg);
  }
}

.shake-trigger {
  &:hover {
    .shake-target, img {
      animation: shake 3s infinite linear;
    }
  }
}
