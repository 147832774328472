body {
  &.admin-bar {

    .fixed-header-nav {
      top: 32px;
    }

  }
}

* {
  font-variant-ligatures: none;

}

ul {
  margin: 0 0;
}

li {
  list-style: none;
}



.page-content {
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

@each $color,
$colorVal in $colors {
  .bg-#{$color} {
    background-color: $colorVal;
  }

  .color-#{$color} {
    color: $colorVal;
  }

}


.padding-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-small-top {
  .row-standard-content-row {
    padding-top: 70px;
  }

}

.padding-small-top-medium-bottom {
  padding-top: 40px;
  padding-bottom: 80px;
}

.padding-normal,
.padding-medium {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-medium-bottom {
  padding-top: 0;
  padding-bottom: 80px;
}

.padding-normal-no-bottom,
.padding-medium-no-bottom {
  padding-top: 80px;
  padding-bottom: 0;

}

.padding-medium-top-small-bottom {
  padding-top: 80px;
  padding-bottom: 40px;
}

.padding-medium-top-xl-bottom {
  padding-top: 80px;
  padding-bottom: 200px;
}

.padding-large {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-large-top {
  padding-top: 120px;
  padding-bottom: 0;
}

.padding-large-bottom {
  padding-bottom: 120px;
}

.padding-large-top-xl-bottom {
  padding-top: 120px;
  padding-bottom: 200px;
}

.padding-xl-top {
  padding-top: 200px;
}

.padding-xl-bottom {
  padding-bottom: 263px;
}

.padding-medium-top-large-bottom {
  padding-top: 80px;
  padding-bottom: 120px;
}

.move-down-70 {
  position: relative;
  z-index: 10;
  margin-bottom: -70px;

  &.padding-medium-no-bottom {
    @include breakpoint(small) {
      padding-bottom: 80px;
    }
  }
}

.btn-lines-wrapper {
  display: inline-block;
  position: relative;
  z-index: 2;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.square-btn {
    padding: 18px 20px;
    margin-top: 30px;
    border-radius: 2px;

    &.white {
      border: 1px solid #fff;
      transition: all 0.5s;

      a {
        color: #fff;
        font-family: TT-Norms, sans-serif;
        font-weight: 700;
        font-size: 12px;
      }

      &:hover {
        background-color: #fff;

        a {
          color: $mspqi-green;
        }
      }
    }

    &.fill-blue {
      background: rgb(9, 160, 166);
      background: linear-gradient(0deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 53%, rgba(9, 160, 166, 1) 100%);

      a {
        color: #fff;
        font-size: 12px;
        font-family: TT-Norms, sans-serif;
        font-weight: 700;
      }
    }
  }

  &.large-btn {
    height: 60px;
    width: 190px;
    border-radius: 10px;
    line-height: 60px;
    letter-spacing: 0.10em;
    transition: all 0.3s;

    &.white {
      border: 1px solid #fff;


      a {
        color: #fff;
        font-family: TT-Norms, sans-serif;
        font-weight: 700;
        font-size: 12px;
      }

      &:hover {
        background-color: #fff;

        a {
          color: $mspqi-green;
        }

      }
    }

    &.square {
      border-radius: 2px;
    }

    &.fill-blue {
      background: rgb(9, 160, 166);
      background: linear-gradient(0deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 53%, rgba(9, 160, 166, 1) 100%);

      a {
        color: #fff;
        font-size: 12px;
        font-family: TT-Norms, sans-serif;
        font-weight: 700;
      }
    }
  }

  &.circle-btn {
    width: 64px;
    height: 64px;
    border: 1px solid #fff;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    background-image: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-down.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 19px 12px;
  }

  &.round-btn {
    border-radius: 50px;
    width: 132px;
    height: 34px;
    line-height: 30px;
    text-align: center;


    &.green {
      border: 1px solid $mspqi-green;

      a {
        color: $mspqi-green;
        font-size: 13px;
        font-family: TT-Norms, sans-serif;
        font-weight: 700;
      }
    }


    &.white {
      border: 1px solid rgba(99, 99, 99, 0.24);

      .breadcrumb {
        color: #636363;

        a {
          font-weight: 800;
          color: #09a0a6;
        }
      }

      a {
        color: #fff;
        font-size: 13px;
        font-family: TT-Norms, sans-serif;
        font-weight: 700;
      }
    }

    @include breakpoint(medium) {
      width: 100px;
      height: 28px;
      line-height: 26px;

      &.green {
        a {
          font-size: 11px;
        }
      }
    }
  }
}

.vert-align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}



.push-up-5 {
  margin-bottom: 5px;
}

/* Cookie Notice */

.cookie-notice-container p.cookie-title {
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-bottom: 5px;
}

.cookie-notice-container p {
  font-weight: 200;
  font-size: 16px;
}

.cookie-notice-container .button {
  margin-bottom: 0;
  color: #9c302e;
  background: #fff;
  border-radius: 8px;
  text-decoration: none;
  border-color: #9c302e;
}

.list-with-no-style ul li {
  list-style: none;
}


/* BLOG SLIDER */
.owl-nav {
  button {
    position: absolute;
    transform: scale(1);
    transition: transform 0.3s;

    @include breakpoint(medium) {
      max-width: 45px;
    }

    &.owl-prev {
      left: -2.5%;
      top: 32%;

      @include breakpoint(medium) {
        left: -3.5%;
      }

      @include breakpoint(small) {
        left: -6.5%;
      }
    }

    &.owl-next {
      right: -2.5%;
      top: 32%;

      @include breakpoint(medium) {
        right: -3.5%;
      }

      @include breakpoint(small) {
        right: -6.5%;
      }
    }
  }
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: none;
  transform: scale(1.1);
  transition: transform 0.3s;
}

.bg-green {
  a {
    color: #fff;
  }

  p {
    a {
      font-weight: 800;

      text-decoration: underline;
    }
  }


}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-gray {
  background-color: #f1f2f2;
}

.bg-white {
  a {
    &:last-child {
      color: $mspqi-green;
      font-weight: 700;
    }
  }

  p {
    a {
      color: $mspqi-green;
      font-weight: 700;
      text-decoration: underline;

      &.pdf-link {
        padding-top: 5px;
        display: inline-block;
      }
    }
  }
}
