.header-wrap {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;

  &.fixed-header-nav {
    position: fixed;
    background-color: #fff;

    .header {


      h1 {
        &::before {
          display: none;
        }




        .fixed {
          display: block;
        }

        
        .header-logo {
         
          color:$mspqi-darkgreen;
       
         p{
          
           &:first-child{
            
             span{
              
               &:after{
              
                background-color:$mspqi-darkgreen;
              
               }

             }
           }
           &:last-child{
            
             span,
             strong{
              
               color:$mspqi-darkgreen;
             }
           }
         }
        }
      }

      nav {
        #menu-main-menu {
          >li {
            color: $mspqi-darkgreen;
            font-weight: 700;

            >a {
              color: $mspqi-darkgreen;

            }
          }

          .sub-menu {
            li {
              a {
                color: #fff;
              }
            }
          }

        }

        .search-wrap {
          border: 1px solid #003443;

          input {
            color: #003443;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              border: none;
              -webkit-text-fill-color: #003443;
              -webkit-box-shadow: none;

            }

            &::-webkit-search-cancel-button {

              -webkit-appearance: none;
            }

            &::placeholder {
              color: #003443;
            }
          }

          button {
            .search-btn {
              display: none;
            }

            .fixed-search-btn {
              display: block;
            }
          }
        }

        .hamburger {

          .hamburger-inner,
          .hamburger-inner:after,
          .hamburger-inner:before {
            background-color: $mspqi-darkgreen;

          }
        }

      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    margin: 0 auto;
    align-items: center;
    max-width: 1520px;
    width: 95%;

    @include breakpoint(medium) {
      padding: 30px 0;
    }

    h1 {
      margin: 0 0;
      font-size: 1em;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 369px;
        height: 161px;
        background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/header-bg.png) no-repeat left top;
        background-size: cover;
        position: absolute;
        left: -50px;
        top: -30px;
        z-index: -1;

        @include breakpoint(medium) {
          width: 300px;
          height: 133px;
        }

        @include breakpoint(small) {
          top: -55px;
        }
      }

      a {
        display: block;

        &.header-logo {
          display: block;
          color:#fff;
          display:flex;
          align-items: center;
         font-family: TT-Norms, sans-serif;
         transform: color 0.5s;
         margin-left: -12px;
         p{
           margin-bottom:0;
           &:first-child{
            
             span{
               font-size:9px;
               line-height:18px;
               transform: rotate(-90deg);
               font-weight:800;
               display: block;
               letter-spacing: 0.19em;
                position: relative;
               text-align: center;
               @include breakpoint(small){
                font-size:8px;
                letter-spacing: .1em;
               }
               &:after{
                content:"";
                display:block;
                height:1px;
                width:90%;
                background-color:#fff;
                position: absolute;
                left:1%;
                margin-top:2px;
                transform: background-color 0.5s;
                @include breakpoint(small){
                  margin-top:0;
                 }
               }

             }
           }
           &:last-child{
             font-size:18px;
             line-height:22px;
             @include breakpoint(small){
              font-size: 11px;
              line-height: 15px;
             }
             span,
             strong{
               display:block;
               color:#fff;
               transform: color 0.5s;
             }
           }
         }
        }


        &.fixed {
          p{
           
            &:first-child{
             
              span{
              
                &:after{
                
                 background-color:$mspqi-darkgreen;
                
                }
 
              }
            }
            &:last-child{
          
              span,
              strong{
                
                color:$mspqi-darkgreen;
              }
            }
          }
        }

        img {
          max-width: 290px;


          @include custom_breakpoint(1120) {
            max-width: 240px;
          }

          @include breakpoint(small) {
            max-width: 200px;
          }
        }
      }

    }

    nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      #menu-main-menu {
        li {
          a {
            font-family: TT-Norms, sans-serif;
          }
        }

        >li {
          display: inline-block;
          color: #fff;
          position: relative;
          padding: 30px 0;


          a {
            color: #fff;
            transition: all 0.3s;
            font-weight: 700;
            font-size: 18px;

            &:hover {
              color: $mspqi-green;
              transition: all 0.3s;

            }

            @include custom_breakpoint(1280) {
              font-size: 14px;
            }

          }

          &:not(:last-child) {
            &::after {
              content: "|";
              display: inline-block;
              padding: 0 14px;

              @include custom_breakpoint(1150) {
                padding: 0 5px;
              }

            }
          }

          >.sub-menu {
            position: absolute;
            left: -20px;
            top: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            margin-left: 0;
            display: none;
            z-index: 5;

            li {
              a {
                width: 100%;
                color: #fff;
                display: block;

                font-size: 15px;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0.05em;

                &:hover {
                  text-decoration: underline;
                }

              }
            }

            >li {
              width: 100%;
              padding: 15px 25px;
              padding-right: 50px;
              position: relative;
              border-bottom: 1px solid rgba(255, 255, 255, 0.25);

              &.menu-item-has-children {
                &::after {
                  content: "";
                  display: block;
                  width: 9px;
                  height: 14px;
                  background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-menu-arrow.png) no-repeat center;
                  background-size: contain;
                  position: absolute;
                  right: 18px;
                  top: 20px;

                }
              }



              >.sub-menu {
                position: absolute;
                left: 100%;
                top: 0;
                margin-left: 0;
                background-color: rgba(1, 197, 202, 0.85);
                display: none;

                li {
                  width: 100%;
                  padding: 15px 25px;
                  min-width: 230px;
                  position: relative;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                }
              }
            }
          }
        }

        @include breakpoint(medium) {
          display: none;
        }

        .menu-item-1127 {
          .sub-menu {
            min-width: 250px;
          }
        }
      }

      .search-wrap {
        border: 1px solid #fff;
        border-radius: 25px;
        margin-left: 14px;
        padding: 0 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;




        input {
          background: transparent;
          border: none;
          margin: 0;
          padding: 0;
          font-size: 14px;
          box-shadow: none;
          max-height: 30px;
          color: #fff;


          @include breakpoint(small) {
            width: 50px;
            -webkit-transition: width 0.4s ease-in-out;
            transition: width 0.4s ease-in-out;
          }


          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            border: none;
            -webkit-text-fill-color: #fff;
            -webkit-box-shadow: none;

          }

          &::-webkit-search-cancel-button {

            -webkit-appearance: none;
          }

          &::placeholder {
            color: #fff;
          }

          &:focus {
            background: transparent;
            border: none;
            box-shadow: none;

            @include breakpoint(small) {
              width: 100%;
            }


          }


        }

        .fixed-search-btn {
          display: none;
        }
      }

      .hamburger {
        padding: 0;
        padding-left: 14px;

        .hamburger-box {
          width: 27px;
        }

        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
          background-color: #fff;
          width: 27px;
          height: 2px;
        }

        .hamburger-inner {
          top: 7px;

          &:before {
            top: 8px;
          }

          &:after {
            top: 16px;
          }


        }

        .hamburger--elastic.is-active .hamburger-inner:after {
          transform: translate3d(0, -18px, 0) rotate(-270deg);
        }

        .hamburger.is-active:hover,
        .hamburger:hover {
          opacity: 1;
        }
      }
    }
  }
}
