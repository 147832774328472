.row-standard {
	position: relative;
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;

	@include breakpoint(medium) {
		margin-bottom: -2px;
	}

	@include breakpoint(small) {
		margin-bottom: -1px;
	}

	&.home-cols {
		@include breakpoint(medium) {
			padding-bottom: 0;
		}
	}

	h2 {
		color: $mspqi-darkgreen;

		@include breakpoint(medium) {
			font-size: 2.1rem;
		}

		@include breakpoint(small) {
			font-size: 28px;
			line-height: 36px;
		}
	}

	h4 {
		@include breakpoint(small) {
			font-size: 12px;
		}
	}

	&.gradient-gray {
		background: rgb(241, 242, 242);
		background: linear-gradient(0deg, rgba(241, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 21%);
	}

	&.gradient-gray2 {
		background: rgb(241, 242, 242);
		background: linear-gradient(0deg, rgba(241, 242, 242, 1) 0%, rgba(250, 250, 250, 1) 21%);
	}

	&.gradient-gray3 {
		background: rgb(255, 255, 255);
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 60%, rgba(251, 251, 251, 1) 100%);
	}

	&.gradient-gray4 {
		background: rgb(255, 255, 255);
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 60%, rgba(250, 252, 252, 1) 100%);
	}

	&.top-minus-80 {
		margin-top: -80px;
	}

	&.top-minus-200 {
		margin-top: -200px;
		padding-top: 250px;
	}

	.cell {
		section {
			margin: 0 10px;
			position: relative;

			.row-standard-image {
				border: 2px solid #fff;

				img {
					display: block;
					width: 100%;
				}
			}

			.image-pods-content {
				margin-top: -100px;
				margin-left: 28px;
				background: #fff;
				width: calc(100% - 28px);
				padding: 25px;
				z-index: 1;
				position: relative;

				@include breakpoint(medium) {
					padding: 15px;
				}

				h3 {
					color: $mspqi-darkgreen;
					font-size: 22px;
					line-height: 28px;
					letter-spacing: 0.005em;
					transition: color 0.5s;

					a {
						color: inherit;
						display: block;
					}

					@include breakpoint(medium) {
						font-size: 19px;
						line-height: 24px;
					}
				}

				p {
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 0.005em;
					transition: color 0.5s;

					@include breakpoint(medium) {
						font-size: 14px;
						line-height: 20px;
					}
				}

				&::before {
					transition: all 0.35s;
					content: '';
					width: 0%;
					overflow: hidden;
					height: 100%;
					background: rgb(9, 160, 166);
					background: linear-gradient(90deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 100%);
					position: absolute;
					top: 0;
					left: 0;
				}

				&:hover {
					&::before {
						background: rgb(9, 160, 166);
						background: linear-gradient(90deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 100%);
						width: 100%;
						overflow: auto;
					}

					h3,
					p {
						color: #fff;
						position: relative;
						z-index: 2;
						transition: color 0.5s;
					}

					.btn-lines-wrapper {
						border: 1px solid #fff;
						background-color: #fff;
					}
				}
			}
		}

		.num-title {
			font-size: 99px;
			font-weight: 100;
			color: rgba(0, 54, 96, 0.2);
			font-family: TT-Norms, sans-serif;

			@include breakpoint(medium) {
				font-size: 50px;
			}
		}
	}

	&.subpage-intro {
		.grid-container {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;

			.breadcrumb {
				padding: 20px;
				border-bottom: 1px solid rgba(99, 99, 99, 0.24);
			}
		}

		&.bg-green {
			.grid-container .breadcrumb {
				border-bottom: 1px solid rgba(225, 255, 255, 0.2);
			}
		}

		&.bg-white {
			.breadcrumb {
				color: #636363;
				font-weight: 400;

				strong {
					font-weight: 800;
					color: $mspqi-green;
				}

				a {
					color: #636363;
					font-weight: 400;

					&:last-child {
						color: #05b2b8;
						font-weight: 700;
					}

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.privacy {
		.row-standard-content-row {
			.cell {
				padding-bottom: 80px;
			}
		}
	}

	&.col-2 {
		.cell:first-child {
			margin-right: 100px;

			@include breakpoint(medium) {
				margin-right: 50px;
			}

			@include breakpoint(small) {
				margin-right: 0;
			}
		}

		.cell {
			div {
				h3 {
					font-size: 40px;
					line-height: 65px;

					@include breakpoint(medium) {
						font-size: 28px;
						line-height: 34px;
					}
				}

				p {
					@include breakpoint(medium) {
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}

		&.col-top {
			@include breakpoint(small) {
				padding-bottom: 180px;
			}
		}
	}

	&.curve-bg-start {
		padding-top: 250px;
		padding-bottom: 80px;
		border-bottom: 1px solid #49b9bd;

		@include breakpoint(small) {
			padding-bottom: 0;
		}
	}

	&.smaller-padding {
		.cell {
			section {
				.image-pods-content {
					margin-left: 20px;
					margin-top: -50px;
					background-color: #fff;
					width: calc(100% - 20px);
					position: relative;
					z-index: 1;
					padding: 16px;

					h3 {
						color: $mspqi-darkgreen;
						font-size: 18px;
						line-height: 22px;
						letter-spacing: 0.005em;
						transition: all 0.3s;
					}

					p {
						font-size: 15px;
						line-height: 22px;
						letter-spacing: 0.005em;

						.orange {
							color: $mspqi-orange;
							text-transform: uppercase;
							font-weight: 700;
							letter-spacing: 0.1em;
							font-size: 12px;
							border-bottom: 1px solid $mspqi-orange;
							transition: all 0.3s;
						}
					}

					&:hover {
						h3 {
							color: #fff;
							transition: all 0.3s;
						}

						p {
							.orange {
								color: #fff;
								border-bottom: 1px solid #fff;
								transition: all 0.3s;
							}
						}
					}
				}
			}

			@include breakpoint(small) {
				margin-bottom: 30px;
			}
		}
	}

	.btn-lines-wrapper {
		margin-top: 45px;
	}

	&.overlay-bg {
		h2 {
			color: #fff;
		}

		p {
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
	}

	&.image-pod {
		.row-standard-header-row {
			@include breakpoint(medium) {
				width: 80%;
				margin: 0 auto;
			}
		}

		.btn-lines-wrapper {
			margin-top: 0;
		}

		.cell {
			section {
				@include breakpoint(medium) {
					margin-bottom: 30px;
				}
			}
		}
	}

	.cell {
		.overlay-pdf {
			display: block;
			position: relative;
			margin: 12px;

			span {
				background-color: rgba(0, 0, 0, 0.5);
				display: block;
				width: 100%;
				height: 100%;
				padding: 27% 0;
				position: absolute;
				opacity: 0;
				transition: all 0.3s;
				color: #fff;
				font-weight: 800;
				text-align: center;
			}

			> img {
				width: 100%;
			}

			&:hover {
				span {
					opacity: 1;
				}
			}
		}
	}

	&.blog-intro {
		padding-bottom: 150px;
	}
}

.home-slider {
	background: url(/wp-content/themes/tourlabs-mspqi/assets/images/HOME/home-bg3.png) no-repeat center;
	background-size: cover;

	@include breakpoint(medium) {
		padding-top: 100px;

		.grid-container {
			width: 90%;
		}
	}

	.row-dynamic-header {
		padding-bottom: 60px;

		h4 {
			color: #fff;
		}

		h3 {
			color: $mspqi-darkgreen;
			font-size: 50px;
			line-height: 65px;
		}

		p {
			color: #fff;
		}
	}

	.row-dynamic-footer {
		a {
			text-transform: uppercase;
		}
	}

	.blog-slider {
		.blog-slide {
			margin: 0 5px;
			min-height: 390px;
			position: relative;

			@include breakpoint(medium) {
				margin: 0 0;
			}

			.blog-inner {
				> a {
					display: block;
					border: 2px solid #fff;
					margin-bottom: 100px;
				}

				> div {
					background: #fff;
					position: absolute;
					background-color: #fff;
					width: calc(100% - 28px);
					right: 0;
					bottom: 0;

					padding: 25px;

					@include breakpoint(small) {
						width: 100%;
					}

					.orange {
						text-transform: uppercase;
						font-size: 12px;
						line-height: 14px;
						letter-spacing: 0.10em;
						font-weight: 700;
						margin-bottom: 0;

						a {
							color: $mspqi-orange;
						}
					}

					@include breakpoint(medium) {
						padding: 15px;

						h3 {
							font-size: 20px;
						}

						.orange {
							line-height: 22px;

							a {
								font-size: 11px;
							}
						}
					}

					h3 {
						font-size: 22px;
						line-height: 35px;
						color: $mspqi-darkgreen;
						margin-bottom: 0;
					}

					p {
						font-weight: 300;
						font-size: 17px;
						line-height: 24px;
						margin-bottom: 30px;
					}

					.btn-lines-wrapper {
						overflow: hidden;
						transition: all 0.3s;
						margin-top: 0;

						&::before {
							border-radius: 50px;
							transition: all 0.85s;
							content: '';
							width: 0%;
							height: 100%;
							background: rgb(9, 160, 166);
							background: linear-gradient(90deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 100%);
							position: absolute;
							top: 0;
							left: 0;
						}

						a {
							transition: all 0.5s;
						}
					}

					&:hover {
						background-color: #f8f8f8;

						.btn-lines-wrapper {
							&::before {
								background: rgb(9, 160, 166);
								background: linear-gradient(90deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 100%);
								width: 100%;
							}

							a {
								color: #fff;
								position: relative;
								z-index: 10;
							}
						}
					}
				}
			}
		}
	}
}

.mailing-list {
	.row-standard-content-row {
		.cell {
			&:first-child {
				margin-right: 60px;

				@include breakpoint(medium) {
					margin-right: 30px;
				}

				@include breakpoint(small) {
					margin-right: 0;
					margin-bottom: 30px;
				}
			}

			h3 {
				font-size: 40px;
				line-height: 65px;
			}
		}
	}

	label {
		margin-bottom: 10px;

		span {
			display: block;
			padding: 0 20px;
			border: 1px solid #fff;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 50px;
			max-width: 300px;

			input {
				background: transparent;
				border: none;
				box-shadow: none;
				margin: 0 0;
				color: #fff;

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					border: none;
					-webkit-text-fill-color: #fff;
					-webkit-box-shadow: transparent;
					transition: background-color 5000s ease-in-out 0s;
				}

				&::placeholder {
					color: #fff;
				}

				&:focus {
					background: transparent;
					border: none;
					box-shadow: none;
				}
			}
		}
	}

	input[type="submit"] {
		color: $mspqi-green;
		background-color: #fff;
		padding: 3px 40px;
		border-radius: 25px;
		border: none;
		font-size: 14px;
		letter-spacing: 0.025em;
		line-height: 30px;
		font-weight: 700;
		font-family: TT-Norms, sans-serif;
		transition: all 0.3s;
		border: 1px solid #fff;
		cursor: pointer;
		margin-top: 8px;

		&:hover {
			background-color: $mspqi-green;
			color: #fff;
			border: 1px solid #fff;
		}
	}
}

.row-accordion-,
.row-accordion-profile {
	padding: 115px 0 80px;

	ul {
		@include breakpoint(medium) {
			width: 90%;
		}

		@include breakpoint(small) {
			width: 80%;
			margin: 0 auto;
		}

		li {
			display: flex;
			margin-bottom: 50px;

			@include breakpoint(small) {
				display: block;
			}

			.profile-img {
				width:189px;
				height:177px;
				margin-right:55px;
				text-align: center;
				position: relative;
				overflow:hidden;
				&::before{
					content:"";
					display:block;
					width:189px;
					height:177px;
					position: absolute;
					left:0;
					top:0;
					background:url(/wp-content/themes/tourlabs-mspqi/assets/icons/profile_foreground.png) no-repeat center;
					background-position: 100%;
					z-index: 10;
				}
				p{
					text-align: left;
    				padding-left: 30px;
				}
				@include breakpoint(small) {
					text-align: left;
					p{
						text-align: center;
						padding-left: 0;
					}
				}
			}

			.profile-content {
				width: calc(100% - 240px);

				@include breakpoint(small) {
					width: calc(100%);
				}

				p {
					strong {
						color: #636363;
					}

					a {
						&.green {
							color: $mspqi-green;
						}

						&.underline {
							text-decoration: underline;
						}

						&.bold {
							font-weight: 700;
						}
					}
				}

				h3 {
					color: $mspqi-darkgreen;
					font-size: 26px;
					line-height: 65px;
					margin-bottom: 0;
				}

				p {
					font-size: 17px;
					line-height: 30px;
					letter-spacing: 0.02em;
				}

				.profile-btn {
					position: relative;

					.profile-accordion-title {
						display: inline-block;
						font-size: 13px;
						padding: 0 20px;
						line-height: 30px;
						border: 1px solid $mspqi-green;
						color: $mspqi-green;
						border-radius: 25px;
						background-color: #fff;
						position: relative;
						z-index: 1;
						font-weight: 700;

						span {
							&:first-child {
								display: inline;
							}

							&:last-child {
								display: none;
							}
						}

						&.opened {
							background-color: $mspqi-green;
							color: #fff;

							span {
								&:first-child {
									display: none;
								}

								&:last-child {
									display: inline;
								}
							}
						}
					}

					&::after {
						content: "";
						display: block;
						width: 100%;
						height: 1px;
						background-color: rgba(99, 99, 99, 0.24);
						position: absolute;
						left: 0;
						top: 50%;
					}
				}

				.profile-accordion-content {
					display: none;
					padding: 25px 0;
					border-bottom: 1px solid rgba(99, 99, 99, 0.24);
				}
			}
		}
	}
}

.row-accordion-faqs {
	padding-bottom: 123px;

	ul {
		li {
			.accordion-title {
				font-size: 19px;
				line-height: 30px;
				font-weight: 800;
				font-family: TT-Norms, sans-serif;
				color: $mspqi-darkgreen;
				border: none;
				border-bottom: 1px solid rgba(99, 99, 99, 0.24);
				padding-right: 50px;

				@include custom_breakpoint(1150) {
					font-size: 17px;
					line-height: 28px;
				}

				&::before {
					content: "";
					background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-arrow-orange.png)no-repeat center;
					background-size: contain;
					width: 16px;
					height: 12px;
					transform: rotate(0);
					transition: transform 0.3s;
				}

				&:focus,
				&:hover {
					background-color: transparent;
				}

				@include breakpoint(small) {
					font-size: 16px;
					line-height: 23px;
					padding-right: 40px;
				}
			}

			&.is-active {
				.accordion-title {
					&::before {
						transform: rotate(-180deg);
						transition: transform 0.3s;
					}
				}
			}

			.accordion-content {
				border: none;
				border-bottom: 1px solid rgba(99, 99, 99, 0.24);
			}
		}
	}

	.tree-graph {
		img {
			image-rendering: pixelated;
		}

		p {
			font-weight: 800;
			font-family: TT-Norms, sans-serif;
			font-size: 20px;
			line-height: 24px;
			color: #003443;

			&:last-child {
				margin-top: 50px;
			}
		}
	}

	.tree-structure {
		display: flex;
		justify-content: center;

		section {
			div {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;

				display: flex;
				justify-content: center;
				align-items: center;

				p {
					font-size: 20px;
					line-height: 24px;
					margin-bottom: 0;
					font-weight: 800;
					font-family: TT-Norms, sans-serif;
					letter-spacing: 0;
					color: #fff;
				}
			}

			&.column-1 {
				> div {
					width: 207px;
					height: 183px;
					padding-left: 47px;
				}
			}

			&.column-2 {
				> div {
					width: 207px;
					height: 181px;
					padding-left: 57px;
					padding-right: 20px;
				}
			}

			&.column-3 {
				> div {
					width: 230px;
					height: 97px;
					padding: 15px;
					display: block;
					margin-bottom: 15px;

					p {
						font-size: 18px;
						line-height: 23px;
					}
				}
			}
		}
	}
	.row-icons {
		background-repeat: repeat-y;
		background-size: 100%;
		padding: 115px 0;
		color: #fff;

		background-position: center bottom;
		background-repeat: none;
		background-size: cover;

		&.no-padding {
			padding: 0 0;
		}

		h2 {
			color: $mspqi-darkgreen;
		}

		.row-icon-content {
			padding: 60px 0;

			.cell {
				display: flex;

				@include breakpoint(small) {
					display: block;
				}

				&.icons-5 {
					@include breakpoint(small) {
						display: flex;
						flex-wrap: wrap;

						> div {
							width: 50%;
						}
					}
				}

				div {
					width: 20%;
					text-align: center;

					p {
						background-size: cover;
						width: 145px;
						height: 128px;
						line-height: 128px;
						margin: 0 auto;

						@include breakpoint(medium) {
							width: 125px;
							height: 110px;
							line-height: 110px;

							img {
								width: 45%;
							}
						}
					}

					h4 {
						letter-spacing: 0;
						font-weight: 800;
						font-size: 22px;
						line-height: 30px;
						width: 80%;
						margin: 30px auto;

						@include breakpoint(medium) {
							font-size: 14px;
							line-height: 22px;
						}

						@include breakpoint(small) {
							font-size: 18px;
							line-height: 26px;
						}
					}
				}

				&.icons-3 {
					justify-content: center;

					> div {
						margin: 0 70px;
						width: auto;

						@include breakpoint(medium) {
							margin: 0 0;
						}

						section {
							padding: 20px 0;
							width: 255px;
							margin: 0 auto;

							@include breakpoint(medium) {
								width: 220px;
							}

							h4 {
								margin: 0 0;
								line-height: 32px;
								width: 100%;
								color: $mspqi-darkgreen;

								@include breakpoint(medium) {
									font-size: 20px;
								}
							}

							p {
								line-height: 30px;
								width: 100%;
								font-size: 20px;

								@include breakpoint(medium) {
									font-size: 16px;
									line-height: 25px;
								}

								a {
									color: #fff;

									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.row-members {
		padding: 83px 0;

		@include breakpoint(medium) {
			width: 90%;
			margin: 0 auto;
			padding: 0 0 83px;
		}

		@include breakpoint(small) {
			padding: 0 0 83px;
		}

		div {
			margin: 0 70px;
			width: auto;

			@include breakpoint(medium) {
				margin: 0 0;
			}

			section {
				padding: 20px 0;
				width: 255px;
				margin: 0 auto;

				@include breakpoint(medium) {
					width: 220px;
				}

				h4 {
					margin: 0 0;
					line-height: 32px;
					width: 100%;
					color: $mspqi-darkgreen;

					@include breakpoint(medium) {
						font-size: 20px;
					}
				}

				p {
					line-height: 30px;
					width: 100%;
					font-size: 20px;

					@include breakpoint(medium) {
						font-size: 16px;
						line-height: 25px;
					}

					a {
						color: #fff;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

.row-members {
	padding: 83px 0;

	@include breakpoint(medium) {
		width: 90%;
		margin: 0 auto;
		padding: 0 0 83px;
	}

	@include breakpoint(small) {
		padding: 0 0 83px;
	}

	.grid-container {
		max-width: 900px;

		hr {
			margin: 5px 0;
			max-width: 1170px;
			border-bottom: 1px solid rgba(99, 99, 99, .49);
		}

		.grid-x {
			border-bottom: 1px solid rgba(99, 99, 99, .49);

			.cell {
				> div {
					padding-bottom: 40px;

					@include breakpoint(medium) {
						padding-right: 20px;
					}

					h3 {
						color: #003660;
						font-size: 24px;
						line-height: 40px;
						margin-bottom: 0;

						@include breakpoint(medium) {
							font-size: 20px;
							line-height: 27px;
						}

						span {
							font-weight: 300;
							font-size: 20px;
						}
					}

					p {
						font-size: 17px;
						margin-bottom: 0;

						@include breakpoint(medium) {
							font-size: 15px;
							line-height: 22px;
						}
					}
				}
			}
		}
	}

	.memebers-title {
		padding: 20px 0 60px;

		h3 {
			color: #09a0a6;
			font-size: 20px;
		}
	}
}

.row-image-column {
	padding: 80px 0;

	.cell {
		display: flex;
		justify-content: center;

		&:nth-child(n + 5) {
			display: none;
		}

		.column-image {
			margin-right: 40px;
			margin-bottom: 40px;

			a {
				display: block;
				border: 1px solid rgba(99, 99, 99, .23);

				img {
					width: 100%;
				}
			}
		}

		.column-content {
			width: 487px;
			max-width: 487px;

			h4 {
				letter-spacing: 0;
				font-weight: 800;
				line-height: 28px;
				color: $mspqi-darkgreen;
				max-width: 370px;
			}

			p {
				font-size: 18px;
				line-height: 26px;

				&.orange {
					color: $mspqi-orange;
					font-size: 12px;
					line-height: 20px;
					text-transform: uppercase;
					font-weight: 800;
					font-family: TT-Norms, sans-serif;
				}
			}
		}

		@include breakpoint(small) {
			display: block;

			.column-image {
				margin-right: 0;
			}

			.column-content {
				width: 100%;
			}
		}
	}

	.show-more {
		position: relative;
		text-align: center;
		padding: 30px 0;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			background-color: rgba(99, 99, 99, 0.24);
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.show-more-btn {
			border-radius: 25px;
			background: rgb(9, 160, 166);
			background: linear-gradient(0deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 53%);
			padding: 12px 35px;
			font-weight: 800;
			color: #fff;
			position: relative;
			z-index: 1;
			transition: all 0.3s;
			border: 1px solid #fff;

			&:hover {
				background: #fff;
				color: $mspqi-green;
				border: 1px solid $mspqi-green;
			}
		}
	}
}

.inquiry-form {
	.row-standard-header-row {
		width: 900px;
		margin: 0 auto;
	}

	form {
		padding-top: 50px;

		input,
		textarea {
			border: 1px solid #c0c0c1;
			border-radius: 5px;
			background-color: #f8f8f8;
			margin-bottom: 23px;
		}

		.inquiry-checkbox {
			text-align: left;
			display: flex;
			align-items: center;

			&::before {
				content: "";
				display: inline-block;
				border: 1px solid #c0c0c1;
				background-color: #f8f8f8;
				width: 27px;
				height: 27px;
				border-radius: 5px;
				margin-right: 10px;

				@include breakpoint(medium) {
					min-width: 20px;
					height: 23px;
					margin-right: 5px;
				}

				@include breakpoint(small) {
					height: 22px;
				}
			}

			input {
				display: none;
			}

			.wpcf7-list-item-label {
				font-size: 15px;
				font-weight: 300;

				@include breakpoint(small) {
					font-size: 11px;
					line-height: 18px;
					display: block;
				}
			}
		}

		.submit-wrap {
			text-align: left;
			padding: 32px 0;

			.wpcf7-submit {
				border-radius: 2px;
				width: 190px;
				height: 60px;
				color: #fff;
				background: rgb(9, 160, 166);
				background: linear-gradient(0deg, rgba(9, 160, 166, 1) 0%, rgba(1, 197, 202, 1) 52%);
				border: none;
				font-size: 12px;
				font-weight: 800;
				text-transform: uppercase;
				outline: none;
			}
		}
	}
}

.dynamic-content {
	padding: 100px 0;

	&.blog_main {
		background: url(/wp-content/themes/tourlabs-mspqi/assets/images/SUB/contact-bg02.png) no-repeat center top;
		background-size: cover;
		margin-top: -200px;
		padding-top: 200px;
		position: relative;

		.blog-main {
			padding-right: 60px;

			@include breakpoint(medium) {
				padding-right: 0;
			}

			.blog-feature {
				.blog-post-image {
					position: relative;
					width: 100%;

					a {
						display: block;

						img {
							width: 100%;
						}
					}

					.blog-post-desc {
						.blog-desc-inner {
							h2 {
								font-size: 32px;

								@include breakpoint(medium) {
									font-size: 24px;
								}
							}

							.blog-excerpt p {
								font-size: 18px;
								line-height: 28px;
							}
						}
					}

					.blog-date {
						font-family: TT-Norms, sans-serif;
						text-transform: uppercase;
						font-weight: 300;
						position: absolute;
						left: 10px;
						top: 0;
						min-width: 70px;
						padding: 15px 0;
						background-color: #09a0a6;
						color: #fff;
						z-index: 1;
						text-align: center;
						line-height: 32px;
						font-size: 20px;
						letter-spacing: 0.10em;
						-webkit-box-shadow: -8px 10px 18px 0px rgba(0, 0, 0, 0.2);
						-moz-box-shadow: -8px 10px 18px 0px rgba(0, 0, 0, 0.2);
						box-shadow: -8px 10px 18px 0px rgba(0, 0, 0, 0.2);

						span {
							font-size: 26px;
							display: block;
						}

						@include breakpoint(medium) {
							min-width: 50px;
							padding: 10px 0;
							line-height: 23px;
							font-size: 14px;

							span {
								font-size: 20px;
							}
						}
					}
				}
			}

			.blog-desc-inner {
				padding-bottom: 60px;

				h2 {
					padding: 14px 0 5px;
					margin: 0 0;
				}

				.blog-post-subtitle {
					display: flex;
					align-items: center;
					padding-bottom: 10px;

					p {
						font-family: TT-Norms, sans-serif;
						color: $mspqi-orange;
						text-transform: uppercase;
						font-size: 12px;
						line-height: 31px;
						font-weight: 800;
						margin: 0 0;

						a {
							color: $mspqi-orange;
						}

						&.blog-author {
							padding-left: 20px;
							padding-right: 10px;
							background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-author.png) no-repeat
								left center;
							background-size: 13px 15px;
						}

						&.blog-category {
							display: flex;
							align-items: center;
							padding-left: 20px;
							padding-right: 20px;
							background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-tag.png) no-repeat left
								center;
							background-size: 14px 14px;
						}
					}
				}

				.blog-excerpt {
					p {
						color: #636363;
					}
				}
			}

			.blog-post-inner {
				position: relative;

				.blog-post-image {
					a {
						display: block;
					}
				}

				.blog-date {
					text-transform: uppercase;
					position: absolute;
					font-family: TT-Norms, sans-serif;
					left: 10px;
					font-weight: 300;
					top: 0;
					min-width: 50px;
					padding: 10px 0;
					background-color: #09a0a6;
					color: #fff;
					z-index: 1;
					text-align: center;
					line-height: 23px;
					font-size: 14px;
					letter-spacing: 0.010em;
					-webkit-box-shadow: -8px 10px 18px 0px rgba(0, 0, 0, 0.2);
					-moz-box-shadow: -8px 10px 18px 0px rgba(0, 0, 0, 0.2);
					box-shadow: -8px 10px 18px 0px rgba(0, 0, 0, 0.2);

					span {
						display: block;
						font-size: 20px;
					}
				}

				.blog-desc-inner {
					h2 {
						font-size: 24px;
					}

					.blog-excerpt {
						p {
							font-size: 17px;
							line-height: 24px;
						}
					}
				}
			}

			.blog-post {
				&:nth-child(odd) {
					.blog-post-inner {
						padding-right: 10px;
					}
				}

				&:nth-child(even) {
					.blog-post-inner {
						padding-left: 10px;
					}
				}
			}
		}

		.post-pagination {
			border-top: 1px solid rgba(99, 99, 99, 0.24);
			padding: 20px 0;

			span,
			a {
				border: 1px solid #b7b7b7;
				width: 25px;
				height: 25px;
				line-height: 23px;
				text-align: center;
				color: #636363;
				display: inline-block;
				font-size: 15px;
			}

			span {
				background-color: #05b2b8;
				color: #fff;
				border: 1px solid #05b2b8;
				font-weight: 800;
			}
		}

		.blog-sidebar {
			> div {
				margin-bottom: 37px;
			}

			.blog-search {
				form {
					fieldset {
						position: relative;
						width: 100%;
						height: 43px;
						border: 1px solid #e1e1e1;
						border-radius: 10px;
						background: rgb(7, 8, 8);
						background: linear-gradient(0deg, rgba(7, 8, 8, 0.2) 0%, rgba(255, 255, 255, 0.2) 62%);

						input {
							border: none;
							background: transparent;
							-webkit-box-shadow: none;
							box-shadow: none;
							position: absolute;
						}

						#s {
							width: calc(100% - 36px);
							left: 10px;
							top: 50%;
							-webkit-transform: translateY(-50%);
							transform: translateY(-50%);

							&::placeholder {
								color: #464646;
							}

							&:-webkit-autofill,
							&:-webkit-autofill:hover,
							&:-webkit-autofill:focus {
								border: none;
								-webkit-text-fill-color: #464646;
								-webkit-box-shadow: transparent;
								transition: background-color 5000s ease-in-out 0s;
							}

							&::-webkit-search-cancel-button {
								-webkit-appearance: none;
							}
						}

						#searchsubmit {
							width: 19px;
							height: 20px;
							background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-search-blue.png)
								no-repeat 50%;
							background-size: contain;
							right: 10px;
							top: 50%;
							-webkit-transform: translateY(-50%);
							transform: translateY(-50%);
							color: transparent;
						}
					}
				}
			}

			h4 {
				letter-spacing: 0;
				color: $mspqi-darkgreen;
				font-weight: 800;
				border-bottom: 1px solid rgba(99, 99, 99, 0.24);
				padding: 5px 0;
			}

			.blog-categories {
				ul {
					.current-cat {
						a {
							color: $mspqi-green;
							font-weight: 800;
						}
					}

					a {
						color: #464646;
					}
				}
			}

			.blog-archives select {
				margin-top: 10px;
				padding-left: 18px;
				position: relative;
				width: 100%;
				height: 43px;
				border: 1px solid #e1e1e1;
				border-radius: 10px;
				background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-archieve.png) no-repeat 100% 0,
					-webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(43%, hsla(0, 0%, 96.5%, .71)), to(rgba(3, 2, 3, .33)));
				background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-archieve.png) no-repeat 100% 0,
					linear-gradient(180deg, #fff, hsla(0, 0%, 96.5%, .71) 43%, rgba(3, 2, 3, .33));
				color: #464646;
			}

			.blog-social-list {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.blog-tags-wrapper {
				color: #636363;

				a {
					font-size: 15px;
					color: #636363;

					&:hover {
						color: $mspqi-green;
					}
				}
			}
		}
	}
}

.blog-breadcrumb {
	height: 70px;
	padding: 20px 0;
	color: #636363;
	border-bottom: 1px solid rgba(99, 99, 99, 0.24);

	a {
		color: #636363;

		&:hover {
			color: $mspqi-green;
		}
	}

	strong {
		font-weight: 800;
		color: $mspqi-green;
	}
}

.blog-wrap {
	padding: 65px 0 80px;

	.blog-main {
		padding-right: 60px;

		.feature-image {
			position: relative;

			img {
				width: 100%;
			}

			.blog-date {
				position: absolute;
				text-transform: uppercase;
				left: 10px;
				top: 0;
				max-width: 70px;
				padding: 15px 0;
				color: #fff;
				background-color: #09a0a6;
				font-size: 20px;
				line-height: 32px;
				font-family: TT-Norms, sans-serif;
				font-weight: 300;
				letter-spacing: 0.10em;

				span {
					font-size: 26px;
					display: block;
				}
			}
		}

		.blog-post-image {
			position: relative;
			width: 100%;

			a {
				display: block;

				img {
					width: 100%;
				}
			}

			.blog-post-desc {
				.blog-desc-inner {
					.blog-excerpt p {
						font-size: 18px;
						line-height: 28px;
					}
				}
			}

			.blog-date {
				text-transform: uppercase;
				font-family: TT-Norms, sans-serif;
				font-weight: 300;
				position: absolute;
				left: 10px;
				top: 0;
				min-width: 70px;
				padding: 10px 0;
				background-color: #09a0a6;
				color: #fff;
				z-index: 1;
				text-align: center;
				line-height: 32px;
				font-size: 20px;
				letter-spacing: 0.10em;
				-webkit-box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.19);
				-moz-box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.19);
				box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.19);

				span {
					display: block;
					font-size: 24px;
				}
			}
		}

		.blog-desc-inner {
			h2 {
				padding: 30px 0 5px;
				margin: 0 0;
				color: $mspqi-darkgreen;
				font-size: 32px;
			}

			.blog-post-subtitle {
				display: flex;
				align-items: center;
				padding-bottom: 10px;

				p {
					font-family: TT-Norms, sans-serif;
					color: $mspqi-orange;
					text-transform: uppercase;
					font-size: 12px;
					line-height: 31px;
					font-weight: 800;
					margin: 0 0;

					a {
						color: $mspqi-orange;
					}

					&.blog-author {
						padding-left: 20px;
						padding-right: 10px;
						background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-author.png) no-repeat left
							center;
						background-size: 13px 15px;
					}

					&.blog-category {
						display: flex;
						align-items: center;
						padding-left: 20px;
						padding-right: 20px;
						background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-tag.png) no-repeat left
							center;
						background-size: 14px 14px;
					}
				}
			}

			.blog-excerpt {
				p {
					color: #636363;
				}
			}
		}

		.blog-post-inner {
			position: relative;

			.blog-post-image {
				a {
					display: block;
				}
			}

			.blog-date {
				position: absolute;
				text-transform: uppercase;
				font-family: TT-Norms, sans-serif;
				left: 10px;
				font-weight: 300;
				top: 0;
				max-width: 50px;
				padding: 15px 0;
				background-color: $mspqi-green;
				color: #fff;
				z-index: 1;
				text-align: center;
				line-height: 23px;
				font-size: 20px;
				letter-spacing: 0.010em;
				-webkit-box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.19);
				-moz-box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.19);
				box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.19);
			}

			.blog-desc-inner {
				h2 {
					font-size: 24px;
				}

				.blog-excerpt {
					p {
						font-size: 17px;
						line-height: 24px;
					}
				}
			}
		}

		h3 {
			color: $mspqi-darkgreen;
			font-size: 26px;
		}

		.wp-block-gallery {
			padding: 50px 0;

			.blocks-gallery-item__caption {
				background: transparent;
				bottom: -40px;
				font-size: 14px;
				color: #636363;
				text-align: left;
				padding: 40px 0 9px;
			}
		}

		.wp-block-image {
			padding-top: 65px;

			figcaption {
				font-size: 14px;
				color: #636363;
				text-align: left;
			}
		}

		.blog-main-footer {
			margin-top: 30px;
			border-top: 1px solid rgba(99, 99, 99, 0.22);

			section {
				padding: 20px 0;

				border-bottom: 1px solid rgba(99, 99, 99, 0.22);

				strong {
					color: $mspqi-darkgreen;
				}

				a {
					color: #636363;

					&:hover {
						color: $mspqi-green;
					}
				}
			}

			.blog-nav {
				text-align: center;
				padding: 20px 0;

				border-bottom: 1px solid rgba(99, 99, 99, 0.22);

				a {
					font-weight: 800;
					font-family: TT-Norms, sans-serif;
					color: $mspqi-darkgreen;
					font-size: 18px;

					&:hover {
						color: $mspqi-green;

						&::before {
							color: $mspqi-darkgreen;
						}
					}

					&:last-child {
						&[rel="next"] {
							&::before {
								content: "|";
								display: inline-block;
								padding: 0 14px;
								font-weight: 300;
							}
						}
					}
				}
			}

			> p {
				font-size: 18px;
				line-height: 28px;
				color: #636363;
			}
		}

		.blog-sidebar {
			> div {
				margin-bottom: 37px;
			}

			.blog-search {
				form {
					fieldset {
						position: relative;
						width: 100%;
						height: 43px;
						border: 1px solid #e1e1e1;
						border-radius: 10px;
						background: rgb(7, 8, 8);
						background: linear-gradient(0deg, rgba(7, 8, 8, 0.2) 0%, rgba(255, 255, 255, 0.2) 62%);

						input {
							border: none;
							background: transparent;
							-webkit-box-shadow: none;
							box-shadow: none;
							position: absolute;
						}

						#s {
							width: calc(100% - 36px);
							left: 10px;
							top: 50%;
							-webkit-transform: translateY(-50%);
							transform: translateY(-50%);

							&::placeholder {
								color: #464646;
							}
						}

						#searchsubmit {
							width: 19px;
							height: 20px;
							background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-search-blue.png)
								no-repeat 50%;
							background-size: contain;
							right: 10px;
							top: 50%;
							-webkit-transform: translateY(-50%);
							transform: translateY(-50%);
							color: transparent;
						}
					}
				}
			}

			h4 {
				letter-spacing: 0;
				color: $mspqi-darkgreen;
				font-weight: 800;
				border-bottom: 1px solid rgba(99, 99, 99, 0.24);
				padding: 5px 0;
			}

			.blog-categories {
				ul {
					.current-cat {
						a {
							color: $mspqi-green;
							font-weight: 800;
						}
					}

					a {
						color: #464646;
					}
				}
			}

			.blog-archives select {
				margin-top: 10px;
				padding-left: 18px;
				position: relative;
				width: 100%;
				height: 43px;
				border: 1px solid #e1e1e1;
				border-radius: 10px;
				background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-archieve.png) no-repeat 100% 0,
					-webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(43%, hsla(0, 0%, 96.5%, .71)), to(rgba(3, 2, 3, .33)));
				background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-archieve.png) no-repeat 100% 0,
					linear-gradient(180deg, #fff, hsla(0, 0%, 96.5%, .71) 43%, rgba(3, 2, 3, .33));
				color: #464646;
			}

			.blog-social-list {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.blog-tags-wrapper {
				color: #636363;

				a {
					font-size: 15px;
					color: #636363;

					&:hover {
						color: $mspqi-green;
					}
				}
			}
		}
	}
}

.bg-white {
	.sitemap,
	.list-pages-shortcode {
		color: #555555;
		text-align: left;
		font-family: open-sans, sans-serif;
		font-weight: 300;
		font-size: 21px;
		padding: 70px 0 100px;

		li {
			&:before {
				content: "·";
				font-size: 20px;
				vertical-align: middle;
				line-height: 20px;
			}

			line-height: 38px;

			a {
				color: #555555;
				font-weight: 300;
				text-decoration: underline;
				transition: all 0.3s;
				margin-left: 20px;

				&:hover {
					font-weight: 800;
				}
			}

			p {
				margin: 0 0;
			}
		}
	}

	@include breakpoint(small) {
		font-size: 16px;

		.two-lines {
			display: flex;

			&:before {
				line-height: 35px;
			}
		}

		li {
			a {
				margin-left: 10px;
			}
		}
	}
}

.row-icons {
	background-repeat: repeat-y;
	background-size: 100%;
	padding: 115px 0;
	color: #fff;

	background-position: center bottom;
	background-repeat: none;
	background-size: cover;

	&.no-padding {
		padding: 0 0;
	}

	h2 {
		color: $mspqi-darkgreen;
	}

	.row-icon-content {
		padding: 60px 0;

		.cell {
			display: flex;

			@include breakpoint(small) {
				display: block;
			}

			&.icons-5 {
				@include breakpoint(small) {
					display: flex;
					flex-wrap: wrap;

					> div {
						width: 50%;
					}
				}
			}

			div {
				width: 20%;
				text-align: center;

				p {
					background-size: cover;
					width: 145px;
					height: 128px;
					line-height: 128px;
					margin: 0 auto;

					@include breakpoint(medium) {
						width: 125px;
						height: 110px;
						line-height: 110px;

						img {
							width: 45%;
						}
					}
				}

				h4 {
					letter-spacing: 0;
					font-weight: 800;
					font-size: 22px;
					line-height: 30px;
					width: 80%;
					margin: 30px auto;

					@include breakpoint(medium) {
						font-size: 14px;
						line-height: 22px;
					}

					@include breakpoint(small) {
						font-size: 18px;
						line-height: 26px;
					}
				}
			}

			&.icons-3 {
				justify-content: center;

				> div {
					margin: 0 70px;
					width: auto;

					@include breakpoint(medium) {
						margin: 0 0;
					}

					section {
						padding: 20px 0;
						width: 255px;
						margin: 0 auto;

						@include breakpoint(medium) {
							width: 220px;
						}

						h4 {
							margin: 0 0;
							line-height: 32px;
							width: 100%;
							color: $mspqi-darkgreen;

							@include breakpoint(medium) {
								font-size: 20px;
							}
						}

						p {
							line-height: 30px;
							width: 100%;
							font-size: 20px;

							@include breakpoint(medium) {
								font-size: 16px;
								line-height: 25px;
							}

							a {
								color: #fff;

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}
}