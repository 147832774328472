@font-face {
  font-family: TT-Norms;
  src: url(/wp-content/themes/tourlabs-mspqi/dist/fonts/TTNorms-Regular.ttf);
}

@font-face {
  font-family: TT-Norms;
  src: url(/wp-content/themes/tourlabs-mspqi/dist/fonts/TTNorms-Thin.ttf);
  font-weight: 100;
}


@font-face {
  font-family: TT-Norms;
  src: url(/wp-content/themes/tourlabs-mspqi/dist/fonts/TTNorms-Light.ttf);
  font-weight: 300;
}




@font-face {
  font-family: TT-Norms;
  src: url(/wp-content/themes/tourlabs-mspqi/dist/fonts/TTNorms-Bold.ttf);
  font-weight: 800;
}

body {
  font-family: open-sans, sans-serif;
  font-weight: 300;
  font-variant: normal;
  color: #636466;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: TT-Norms, sans-serif;
  font-weight: 800;


  &.orange {
    color: $mspqi-orange;
  }

  &.green {
    color: $mspqi-green;
  }

  strong {
    color: $mspqi-darkblue;
  }
}

h4 {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.26em;

}

p {
  font-family: open-sans, sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0;

  @include breakpoint(small) {
    font-size: 16px;
    line-height: 27px;

  }
}

a {
  font-family: inherit;
}
