// ----------------------
// COLORS
// ----------------------

$mspqi-darkgreen: #003443;
$mspqi-green: #05b2b8;
$mspqi-orange: #da5302;
$mspqi-darkblue: #003443;
$colors: (white: #fff,
  green: #09a0a6);


// ----------------------
// BREAKPOINTS
// ----------------------
$breakpoints: (small: 640px,
  medium: 1024px,
  large: 1200px);
$breakpoint-classes: (small medium large);

@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media all and (max-width: map-get($breakpoints, $size)) {
      @content;
    }
  }

  @else {
    @warn "Unknown `#{$size}` in $breakpoints.";
  }
}

@mixin custom_breakpoint($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}


// ------------------------------------
// LETTER-SPACING WITH CENTER ALIGN FIX
// ------------------------------------
@mixin letter-spacing-and-text-align-center($letter-spacing) {

  // problem
  letter-spacing: $letter-spacing; // have fun when they went 
  text-align: center; // both of them together!!

  // solution                               // To fight end-of-line letter-spacing :
  padding-left: $letter-spacing * 2.5; // move all your content to the right
}
