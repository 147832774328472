.slidebars-menu {
  font-size: 16px;

  ul {
    margin: 0 0;
    display: none;

  }

  .menu-item-has-children {
    >a {
      position: relative;

      span {
        content: "";
        display: block;
        width: 50%;
        height: 100%;
        background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-menu-down.png) no-repeat;
        background-position: center right 30px;
        background-size: 12px 7px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(0);
        transition: all 0.3s;
        padding-right: 32px;

      }

      &.active {
        span {
          background: url(/wp-content/themes/tourlabs-mspqi/assets/icons/icon-menu-up.png) no-repeat;
          background-position: center right 30px;

        }
      }
    }

  }

  >li {

    >a {
      font-weight: 800;
      color: $mspqi-darkgreen;
      border-bottom: 1px solid #e1e1e1;
      padding: 15px 25px;
      display: block;
    }

    &.menu-item-has-children {

      >a.active {
        color: $mspqi-green;
      }

    }

  }

  .sub-menu {
    font-size: 14px;

    >li {

      a {

        color: $mspqi-darkgreen;
        border-bottom: 1px solid #e1e1e1;
        padding: 15px 38px;

        &:hover {
          font-weight: 800;
        }

        &.active {
          font-weight: 800;

        }
      }
    }


    .sub-menu {
      background-color: #f1f2f2;

      a {
        font-weight: 300;
        color: $mspqi-darkgreen;

      }
    }
  }


}
