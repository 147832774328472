.icon-phone {
  &::before {
    content: "";
    background: center url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-phone.png) no-repeat;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 2px;
    margin-right: 6px;
  }
}

.icon-phone-black {
  &::before {
    content: "";
    background: center url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-phone-black.png) no-repeat;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 2px;
    margin-right: 6px;
    vertical-align: middle;
  }
}

.icon-mail {
  &::before {
    content: "";
    background: center url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-mail.png) no-repeat;
    display: inline-block;
    width: 17px;
    height: 10px;
    margin-right: 5px;
  }
}

.icon-mail-black {
  &::before {
    content: "";
    background: center url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-mail-black.png) no-repeat;
    display: inline-block;
    width: 17px;
    height: 10px;
    margin-right: 5px;
    vertical-align: middle;
  }
}
